import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Navigation from "../components/navigation/navigation"
import Newsletter from "../components/newsletter/newsletter"
import Lists from "../components/lists/lists"
import SEO from "../components/seo/seo"
import Slices from "../slices/"

export const query = graphql`
  query IndexQuery {
    prismic {
      allNavigations {
        edges {
          node {
            subscription_error_message
            subscription_success_message
            header {
              ... on PRISMIC_NavigationHeaderMenu {
                primary {
                  title
                  type
                  link {
                    _linkType
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
                fields {
                  link {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                          parent {
                            ... on PRISMIC_Page {
                              title
                              _meta {
                                uid
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  title
                  overview
                }
              }
            }
            footer {
              ... on PRISMIC_NavigationFooterMenu {
                primary {
                  title
                }
                fields {
                  indented
                  link {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                          parent {
                            ... on PRISMIC_Page {
                              title
                              _meta {
                                uid
                              }
                            }
                          }
                        }
                      }
                    }                  
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
      pagesMain: allPages(lang: "en-gb", uid: "home") {
        edges {
          node {
            title
            meta_title
            meta_description
            meta_keywords
            _meta {
              id
              uid
              type
            }
            parent {
              ... on PRISMIC_Page {
                _meta {
                  uid
                }
              }
            }
            body {
              ... on PRISMIC_PageBodyDownload {
                type
                primary {
                  headline
                  description
                  form_id
                  file {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ImageLink {
                      url
                    }
                  }
                  image
                  imageSharp {
                    id
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyFeatures_with_graphics {
                type
                primary {
                  headline
                }
                fields {
                  background_color
                  heading
                  description
                  image
                  read_more_label
                  read_more_link {
                    _linkType
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                    ... on PRISMIC_Page {
                      admin_title
                      title
                      _meta {
                        uid
                      }
                      parent {
                        _linkType
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                  }
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyFeatures {
                type
                primary {
                  button_title
                  theme
                  button_link {
                    _linkType
                    ... on PRISMIC_Page {
                      title
                      _linkType
                      _meta {
                        uid
                      }
                      parent {
                        _linkType
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                  }
                }
                fields {
                  headline
                  summary
                  text
                  icon
                  iconSharp {
                    childImageSharp {
                      fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyHero {
                type
                primary {
                  headline
                  summary
                }
                fields {
                  title
                  video
                  link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                    _linkType          
                    ... on PRISMIC_Page {
                      title
                      _linkType
                      _meta {
                        uid
                      }
                      parent {
                        _linkType
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyHero_grid {
                type
                primary {
                  headline
                  summary                 
                }
                fields {
                  title
                  video
                  link {
                    _linkType
                    ... on PRISMIC_Pricing {
                      _linkType
                      meta_title
                      meta_description
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Page {
                      title
                      _linkType
                      _meta {
                        uid
                      }
                      parent {
                        _linkType
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyOverview {
                type
                primary {
                  theme
                  headline
                  summary
                }
                fields {
                  title
                  link {
                    _linkType
                    ... on PRISMIC_Page {
                      title
                      _linkType
                      _meta {
                        uid
                      }
                      parent {
                        _linkType
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyDemo {
                type
                primary {
                  headline
                  summary
                }
              }
              ... on PRISMIC_PageBodyDemo_signup {
                type
                primary {
                  headline
                  description
                  what_youllget_heading
                  collaboration_heading
                }
                fields {
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyDemo_signup_simple {
                type
                primary {
                  headline
                  description
                  what_youllget_heading
                  collaboration_heading
                }
                fields {
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyInline_upgrade_form {
                type
                primary {
                  netlify_form_id
                  headline
                  description
                  what_youllget_heading
                  collaboration_heading
                  book_a_demo_heading
                  book_a_demo_description
                }
                fields {
                  list_item
                }
              }
              ... on PRISMIC_PageBodyInline_upgrade_form_full {
                type
                primary {
                  netlify_form_id
                  headline
                  description
                  what_youllget_heading
                  collaboration_heading
                  book_a_demo_heading
                  book_a_demo_description
                }
                fields {
                  list_item
                }
              }
              ... on PRISMIC_PageBodyPlain_list {
                type

                fields {
                  list_item
                }
              }
              ... on PRISMIC_PageBodyNavigation {
                type
              }
              ... on PRISMIC_PageBodyChecklist {
                type
                primary {
                  headline
                  button_title
                  button_link {
                    _linkType
                    ... on PRISMIC_Page {
                      title
                      _linkType
                      _meta {
                        uid
                      }
                      parent {
                        _linkType
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                  }
                }
                fields {
                  title
                }
              }
              ... on PRISMIC_PageBodyPartners {
                type
                primary {
                  headline
                }
                fields {
                  company
                  image
                  imageSharp {
                    id
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyLogos {
                type
                fields {
                  company
                  image
                  imageSharp {
                    id
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyLogos_1 {
                type
                fields {
                  company
                  image
                  imageSharp {
                    id
                    publicURL
                    childImageSharp {
                      fixed(width: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyTestimonials {
                type
                primary {
                  theme
                }
                fields {
                  author
                  position
                  quote
                  logo
                  logoSharp {
                    id
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyStacks {
                type
                fields {
                  headline
                  icon
                  iconSharp {
                    childImageSharp {
                      fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                  link {
                    _linkType
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                      }
                      parent {
                        _linkType
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  text
                }
              }
              ... on PRISMIC_PageBodyAlternate_grid {
                type
                fields {
                  headline
                  description
                  video
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyTabs {
                type
                fields {
                  tab
                  text
                  video
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodySpeakers {
                type
                label
                primary {
                  description_copy
                  heading
                  speaker_subheading
                  subheading
                }
                fields {
                  caption
                  image
                  name
                  image
                  company
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyTabs_trio {
                type
                primary {
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                  blue_text
                  blue_title
                  green_text
                  green_title
                  red_text
                  red_title
                }
              }
              ... on PRISMIC_PageBodyResults {
                type
                fields {
                  headline
                  image
                  description
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodySteps {
                type
                primary {
                  headline
                }
                fields {
                  headline
                  summary
                  url
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 780) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyText {
                type
                primary {
                  text
                }
              }
              ... on PRISMIC_PageBodyFaqs {
                type
                fields {
                  answer
                  question
                }
              }
              ... on PRISMIC_PageBodySnackbar {
                type
                label
                primary {
                  messaging
                  read_more_link {
                    _linkType
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyTech_spec {
                type
                fields {
                  description
                  description__second_column_
                  heading
                }
              }
              ... on PRISMIC_PageBodyFull_width_image {
                type
                primary {
                  image
                  imageSharp {
                    id
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 1170) {
                        ...GatsbyImageSharpFluid_noBase64
                        aspectRatio
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Page = ({ data, ...rest }) => {
  const pagesMain = data.prismic.pagesMain.edges.slice(0, 1).pop()
  const {
    header,
    footer,
    subscription_success_message,
    subscription_error_message,
  } = data.prismic.allNavigations.edges[0].node

  if (!pagesMain) return null

  return (
    <>
      <SEO
        title={pagesMain.node.meta_title || pagesMain.node.title}
        description={pagesMain.node.meta_description}
        keywords={pagesMain.node.meta_keywords}
      />
      <Layout
        header={
          <Header
            showFooterHeader={true}
            navigation={<Navigation items={header} />}
          />
        }
        body={<Slices slices={pagesMain.node.body} />}
        footer={
          <Footer
            navigation={<Lists items={footer} />}
            newsletter={
              <Newsletter
                subscriptionSuccessMessage={subscription_success_message}
                subscriptionErrorMessage={subscription_error_message}
              />
            }
          />
        }
      />
    </>
  )
}

export default Page
